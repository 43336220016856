import * as React from 'react'
import mixpanel from 'mixpanel-browser'
import ZAPOnlineStatus from 'hybrid-ui/components/zap-online-status'
import App from '../App'
import PrivateRoutes from './PrivateRoutes'
import AuthPage from '../modules/auth'
import {createRoutesFromElements, Navigate, redirect, Route} from 'react-router'
import {RouterProvider} from 'react-router/dom'
import {DASHBOARD_SUMMARY, LOGIN} from 'app/routing/getters'
import {isDev, isStaging, sentryCreateBrowserRouter} from 'app/configs'
import {useCurrentUser, useLogout} from 'app/store/auth'
import {getUsername} from 'app/utils/helper/user'
import {ErrorsPage} from '@hybr1d-tech/charizard'
import {ConsoleSwitcher} from 'app/modules/auth/pages/console-switcher/ConsoleSwitcher'

export default function AppRoutes() {
  const currentUser = useCurrentUser()
  const logout = useLogout()

  React.useEffect(() => {
    if (isDev || isStaging) return
    if (!currentUser?.id) return
    mixpanel.identify(currentUser.id)

    mixpanel.register({
      Email: currentUser.work_email,
      Name: getUsername(currentUser),
      'User ID': currentUser.id,
    })

    mixpanel.people.set_once({
      $email: currentUser.work_email,
      $name: getUsername(currentUser),
      $first_name: currentUser.first_name,
      $last_name: currentUser.last_name,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id])

  return (
    <>
      <ZAPOnlineStatus />
      <RouterProvider
        router={sentryCreateBrowserRouter(
          createRoutesFromElements(
            <Route element={<App />}>
              <Route path="error/*" element={<ErrorsPage homeRoute={DASHBOARD_SUMMARY} />} />
              <Route
                path="logout"
                loader={async () => {
                  await logout()
                  return redirect(LOGIN)
                }}
              />
              {currentUser ? (
                <>
                  <Route path="/*" element={<PrivateRoutes />} />
                  <Route index element={<Navigate to={DASHBOARD_SUMMARY} />} />
                </>
              ) : (
                <>
                  <Route path="auth/*" element={<AuthPage />} />
                  <Route path="*" element={<Navigate to="/auth" />} />
                </>
              )}
              <Route path="zenex" element={<ConsoleSwitcher />} />
            </Route>,
          ),
        )}
      />
    </>
  )
}
