import clsx from 'clsx'
import classes from './styles.module.css'
import {SVG} from '@hybr1d-tech/charizard'

interface ZAPIconProps {
  path: string
  variant?:
    | 'primary'
    | 'secondary'
    | 'arch800'
    | 'default'
    | 'theme'
    | 'success'
    | 'warning'
    | 'danger'
    | 'white'
    | 'error'
    | 'info'
    | 'themeTint'
    | any
  onClick?: () => void
  size?: number
  customSpanStyles?: React.CSSProperties
  customSvgStyles?: React.CSSProperties
}

export default function ZAPIcon({
  path,
  variant = 'default',
  onClick,
  size = 16,
  customSpanStyles,
  customSvgStyles,
}: ZAPIconProps) {
  return (
    <SVG
      path={path}
      spanClassName={clsx(classes.iconBox, onClick && classes.pointer)}
      height={size}
      width={size}
      svgClassName={clsx(classes.icon, classes[variant])}
      handleClick={onClick}
      customSpanStyles={customSpanStyles}
      customSvgStyles={customSvgStyles}
    />
  )
}
