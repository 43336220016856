import {ICONS} from 'app/utils/constants/icon'
import {privateRoutes, USER_ROLE} from 'app/routing/authorized-routes'
import {
  APP_STORE,
  ATTENDANCE_POLICIES,
  CHECKLIST,
  DASHBOARD_SUMMARY,
  DOCUMENT,
  // GOALS_PEOPLE_ROOT,
  INVENTORY_HOME,
  IT_SUPPORT,
  LEAVE_POLICIES,
  MDM,
  REPORTS_HOME,
  SETTINGS_PERMISSIONS,
  SOFTWARE_TRACKING,
  TASKS,
  TEAM_MEMBERS,
  WEBSTORE_PRODUCTS,
  WORKFLOWS_HOME,
  ZEN_ALLIANCE_PARTNERSHIPS,
  ZEN_ALLIANCE_RESELLING,
} from 'app/routing/getters'
import {type MenuItem, ModuleID, SidebarSection} from './types'

export const MENU_ITEMS: MenuItem[] = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    route: DASHBOARD_SUMMARY,
    rootRoute: privateRoutes.DASHBOARD,
    icon: {
      default: ICONS.sidebar.dashboard.root,
      active: ICONS.sidebar.dashboard.rootActive,
    },
    moduleId: ModuleID.Core,
    section: SidebarSection.Dashboard,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.finance, USER_ROLE.it],
  },
  {
    key: 'employee_directory',
    name: 'Employee Directory',
    route: TEAM_MEMBERS,
    rootRoute: privateRoutes.TEAM,
    icon: {
      default: ICONS.sidebar.team.employeeDirectory,
      active: ICONS.sidebar.team.employeeDirectoryActive,
    },
    moduleId: ModuleID.Core,
    section: SidebarSection.Team,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.finance, USER_ROLE.it, USER_ROLE.user],
  },
  {
    key: 'inventory_management',
    name: 'Inventory Management',
    route: INVENTORY_HOME,
    rootRoute: privateRoutes.INVENTORY,
    icon: {
      default: ICONS.sidebar.zenIT.inv,
      active: ICONS.sidebar.zenIT.invActive,
    },
    moduleId: ModuleID.InventoryManagement,
    section: SidebarSection.ZenIT,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.it, USER_ROLE.finance],
  },
  {
    key: 'device_management',
    name: 'Mobile Device Management',
    route: MDM,
    rootRoute: privateRoutes.MDM,
    icon: {
      default: ICONS.sidebar.zenIT.mdm,
      active: ICONS.sidebar.zenIT.mdmActive,
    },
    moduleId: ModuleID.InventoryManagement,
    section: SidebarSection.ZenIT,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.it, USER_ROLE.finance],
  },
  {
    key: 'software_management',
    name: 'Software Management',
    route: SOFTWARE_TRACKING,
    rootRoute: privateRoutes.SOFTWARE,
    icon: {
      default: ICONS.sidebar.zenIT.software,
      active: ICONS.sidebar.zenIT.softwareActive,
    },
    moduleId: ModuleID.SoftwareManagement,
    section: SidebarSection.ZenIT,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.it, USER_ROLE.finance],
  },
  {
    key: 'webstore',
    name: 'Webstore',
    route: WEBSTORE_PRODUCTS,
    rootRoute: privateRoutes.WEBSTORE,
    icon: {
      default: ICONS.sidebar.zenIT.webstore,
      active: ICONS.sidebar.zenIT.webstoreActive,
    },
    moduleId: ModuleID.Procurement,
    section: SidebarSection.ZenIT,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.it],
  },
  {
    key: 'it_support',
    name: 'IT Support',
    route: IT_SUPPORT,
    rootRoute: privateRoutes.IT_SUPPORT,
    icon: {
      default: ICONS.sidebar.zenIT.itSupport,
      active: ICONS.sidebar.zenIT.itSupportActive,
    },
    moduleId: ModuleID.ITSupport,
    section: SidebarSection.ZenIT,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.it],
  },
  {
    key: 'integrations',
    name: 'Integrations',
    route: APP_STORE,
    rootRoute: privateRoutes.APP_STORE,
    icon: {
      default: ICONS.sidebar.zenIT.integrations,
      active: ICONS.sidebar.zenIT.integrationsActive,
    },
    moduleId: ModuleID.Integrations,
    section: SidebarSection.ZenIT,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.it],
  },

  {
    key: 'workflow',
    name: 'Workflow',
    route: WORKFLOWS_HOME,
    rootRoute: privateRoutes.WORKFLOWS,
    icon: {
      default: ICONS.sidebar.zenCore.workflow,
      active: ICONS.sidebar.zenCore.workflowActive,
    },
    moduleId: ModuleID.Core,
    section: SidebarSection.ZenCore,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.finance, USER_ROLE.it],
  },

  // {
  //   key: 'goals',
  //   name: 'Goals',
  //   route: GOALS_PEOPLE_ROOT,
  //   rootRoute: privateRoutes.GOALS,
  //   icon: {
  //     default: ICONS.sidebar.zenPerformance.goal,
  //     active: ICONS.sidebar.zenPerformance.goalActive,
  //   },
  //   moduleId: ModuleID.Core,
  //   section: SidebarSection.ZenCore,
  //   rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.finance, USER_ROLE.it],
  // },
  {
    key: 'tasks_approvals',
    name: 'Tasks & Approvals',
    route: TASKS,
    rootRoute: privateRoutes.TASKS,
    icon: {
      default: ICONS.sidebar.zenCore.tasks,
      active: ICONS.sidebar.zenCore.tasksActive,
    },
    moduleId: ModuleID.Core,
    section: SidebarSection.ZenCore,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.finance, USER_ROLE.it, USER_ROLE.user],
  },
  {
    key: 'checklist',
    name: 'Checklist',
    route: CHECKLIST,
    rootRoute: privateRoutes.CHECKLIST,
    icon: {
      default: ICONS.sidebar.zenCore.checklist,
      active: ICONS.sidebar.zenCore.checklistActive,
    },
    moduleId: ModuleID.Core,
    section: SidebarSection.ZenCore,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.finance, USER_ROLE.it, USER_ROLE.user],
  },
  {
    key: 'reports',
    name: 'Reports',
    route: REPORTS_HOME,
    rootRoute: privateRoutes.REPORTS,
    icon: {
      default: ICONS.sidebar.zenCore.report,
      active: ICONS.sidebar.zenCore.reportActive,
    },
    moduleId: ModuleID.Core,
    section: SidebarSection.ZenCore,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.finance],
  },
  {
    key: 'partnerships',
    name: 'Partnerships',
    route: ZEN_ALLIANCE_PARTNERSHIPS,
    rootRoute: privateRoutes.ZEN_ALLIANCE_PARTNERSHIPS,
    icon: {
      default: ICONS.sidebar.zenAlliance.partnerships,
      active: ICONS.sidebar.zenAlliance.partnershipsActive,
    },
    moduleId: ModuleID.Partnerships,
    section: SidebarSection.ZenAlliance,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.it, USER_ROLE.finance],
  },
  {
    key: 'reselling',
    name: 'Reselling',
    route: ZEN_ALLIANCE_RESELLING,
    rootRoute: privateRoutes.ZEN_ALLIANCE_RESELLING,
    icon: {
      default: ICONS.sidebar.zenAlliance.reselling,
      active: ICONS.sidebar.zenAlliance.resellingActive,
    },
    moduleId: ModuleID.Reselling,
    section: SidebarSection.ZenAlliance,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.it, USER_ROLE.finance],
  },
  {
    key: 'leave_management',
    name: 'Leave Management',
    route: LEAVE_POLICIES,
    rootRoute: privateRoutes.LEAVE,
    icon: {
      default: ICONS.sidebar.zenHR.leaveManagement,
      active: ICONS.sidebar.zenHR.leaveManagementActive,
    },
    moduleId: ModuleID.LeaveManagement,
    section: SidebarSection.ZenHR,

    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.it, USER_ROLE.finance, USER_ROLE.user],
  },
  {
    key: 'attendance_management',
    name: 'Attendance Management',
    route: ATTENDANCE_POLICIES,
    rootRoute: privateRoutes.ATTENDANCE,
    icon: {
      default: ICONS.sidebar.zenHR.attendanceManagement,
      active: ICONS.sidebar.zenHR.attendanceManagementActive,
    },
    moduleId: ModuleID.AttendanceManagement,
    section: SidebarSection.ZenHR,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin],
  },
  {
    key: 'document_management',
    name: 'Document Management',
    route: DOCUMENT,
    rootRoute: privateRoutes.DOCUMENT,
    icon: {
      default: ICONS.sidebar.zenHR.documents,
      active: ICONS.sidebar.zenHR.documentsActive,
    },
    moduleId: ModuleID.Core,
    section: SidebarSection.ZenHR,
    rbacRoles: [USER_ROLE.owner, USER_ROLE.admin],
  },

  // Others Section
  // {
  //   key: 'saas_partnerships',
  //   name: 'Saas Partnerships & Licensing',
  //   route: privateRoutes.APP_STORE,
  //   icon: {
  //     default: ICONS.sidebar.appStore.root,
  //     active: ICONS.sidebar.appStore.rootActive,
  //   },
  //   moduleId: ModuleID.SaaSPartnershipsAndLicensing,
  //   section: SidebarSection.Others,
  //   rbacRoles: [USER_ROLE.owner, USER_ROLE.admin],
  // },
  {
    key: 'settings',
    name: 'Settings',
    route: SETTINGS_PERMISSIONS,
    rootRoute: privateRoutes.SETTINGS,
    icon: {
      default: ICONS.sidebar.others.settings,
      active: ICONS.sidebar.others.settingsActive,
    },
    moduleId: ModuleID.Settings,
    section: SidebarSection.Others,
    rbacRoles: [USER_ROLE.owner],
  },
]
