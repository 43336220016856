import * as React from 'react'
import clsx from 'clsx'
import SidebarMenuItem from '../sidebar-menu-item'
import classes from './styles.module.css'
import {Badge} from '@hybr1d-tech/charizard'
import {useModulesStore} from 'app/store/integrations'
import {useSidebarMinimized} from 'app/store/layout'
import {useCurrentUser} from 'app/store/auth'
import {USER_ROLE} from 'app/routing/authorized-routes'
import {MENU_ITEMS} from '../menu-config'
import {INTERNAL_MODULES, isInternalRelease} from '../visibility-control'
import {type MenuItem, SidebarSection, ModuleID} from '../types'

interface SidebarMenuProps {
  isMobile?: boolean
}

export default function SidebarMenu({isMobile = false}: SidebarMenuProps) {
  const modules = useModulesStore(s => s.modules)
  const isMini = useSidebarMinimized()
  const currentUser = useCurrentUser()
  const isException = isInternalRelease(currentUser?.company.id)

  const filteredMenuItems = React.useMemo(() => {
    if (!currentUser) return []

    return MENU_ITEMS.filter(item => {
      // Check module access
      const module = modules.find(m => m.id === item.moduleId)
      if (module && !module.installed && !module.default_installed) {
        return false
      }

      // Check role access
      if (!item.rbacRoles?.includes(currentUser.role as USER_ROLE)) {
        return false
      }

      // Check company-specific access
      if (item.companyAccess) {
        const {allowList, denyList} = item.companyAccess
        if (allowList && !allowList.includes(currentUser.company.id)) {
          return false
        }
        if (denyList && denyList.includes(currentUser.company.id)) {
          return false
        }
      }

      // Check internal/exception access
      if (item.isInternal && !isException) {
        return false
      }

      // Check if module is internal
      if (INTERNAL_MODULES.includes(item.moduleId as ModuleID) && !isException) {
        return false
      }

      return true
    })
  }, [modules, currentUser, isException])

  const sections = React.useMemo(() => {
    const result = {} as Record<SidebarSection, MenuItem[]>

    Object.values(SidebarSection).forEach(section => {
      result[section] = filteredMenuItems.filter(item => item.section === section)
    })

    return result
  }, [filteredMenuItems])

  return (
    <div
      className={clsx(
        classes.menuContainer,
        isMini && classes.menuContainerMini,
        !isMobile && classes.menuOverflow,
      )}
    >
      {sections[SidebarSection.Dashboard].map((item, idx) => (
        <SidebarMenuItem key={idx} item={item} isMobile={isMobile} />
      ))}

      {sections[SidebarSection.Team].length > 0 && (
        <div>
          {!isMini && (
            <div className={clsx('zap-subcontent-medium', classes.sectionTitle)}>Team</div>
          )}
          {sections[SidebarSection.Team].map((item, idx) => (
            <SidebarMenuItem key={idx} item={item} isMobile={isMobile} />
          ))}
        </div>
      )}

      {sections[SidebarSection.ZenIT].length > 0 && (
        <div>
          {!isMini && (
            <div className={clsx('zap-subcontent-medium', classes.sectionTitle)}>Zen IT</div>
          )}
          {sections[SidebarSection.ZenIT].map((item, idx) => (
            <SidebarMenuItem key={idx} item={item} isMobile={isMobile} />
          ))}
        </div>
      )}

      {sections[SidebarSection.ZenCore].length > 0 && (
        <div>
          {!isMini && (
            <div className={clsx('zap-subcontent-medium', classes.sectionTitle)}>Zen Core</div>
          )}
          {sections[SidebarSection.ZenCore].map((item, idx) => (
            <SidebarMenuItem key={idx} item={item} isMobile={isMobile} />
          ))}
        </div>
      )}

      {sections[SidebarSection.ZenAlliance].length > 0 && (
        <div>
          {!isMini && (
            <div className={classes.sectionTitleContainer}>
              <span className={'zap-subcontent-medium'}>Zen Alliance</span>
            </div>
          )}
          {sections[SidebarSection.ZenAlliance].map((item, idx) => (
            <SidebarMenuItem key={idx} item={item} isMobile={isMobile} />
          ))}
        </div>
      )}

      {sections[SidebarSection.ZenHR].length > 0 && (
        <div>
          {!isMini && (
            <div className={classes.sectionTitleContainer}>
              <span className={'zap-subcontent-medium'}>Zen HR</span>
              <Badge>Beta</Badge>
            </div>
          )}
          {sections[SidebarSection.ZenHR].map((item, idx) => (
            <SidebarMenuItem key={idx} item={item} isMobile={isMobile} />
          ))}
        </div>
      )}

      {sections[SidebarSection.Others].length > 0 && (
        <div>
          {!isMini && (
            <div className={clsx('zap-subcontent-medium', classes.sectionTitle)}>Others</div>
          )}
          {sections[SidebarSection.Others].map((item, idx) => (
            <SidebarMenuItem key={idx} item={item} isMobile={isMobile} />
          ))}
        </div>
      )}
    </div>
  )
}
