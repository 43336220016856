import * as React from 'react'
import classes from './styles.module.css'

export default function ZAPOnlineStatus() {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine)
  const [showMessage, setShowMessage] = React.useState(false)
  const messageTimerRef = React.useRef(null)

  React.useEffect(() => {
    // Function to handle going offline
    const handleOffline = () => {
      setIsOnline(false)
      setShowMessage(true)

      // Clear any existing timers
      if (messageTimerRef.current) {
        clearTimeout(messageTimerRef.current)
        messageTimerRef.current = null
      }
    }

    // Function to handle going online
    const handleOnline = () => {
      setIsOnline(true)
      setShowMessage(true)

      // Clear any existing timers
      if (messageTimerRef.current) {
        clearTimeout(messageTimerRef.current)
      }

      // Set timer to hide the "online" message after 5 seconds
      messageTimerRef.current = setTimeout(() => {
        setShowMessage(false)
        messageTimerRef.current = null
      }, 5000)
    }

    // Show initial status if offline
    if (!navigator.onLine) {
      setShowMessage(true)
    }

    // Add event listeners
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)

      if (messageTimerRef.current) {
        clearTimeout(messageTimerRef.current)
      }
    }
  }, []) // Empty dependency array means this effect runs once on mount

  return showMessage ? (
    <p className={isOnline ? classes.online : classes.offline}>
      You are {isOnline ? 'online' : 'offline'}
    </p>
  ) : null
}
