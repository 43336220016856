import * as React from 'react'
import {Loader, LOADER_VARIANT} from '@hybr1d-tech/charizard'
import {useLocation, useNavigate} from 'react-router'
import {useSetAuthKey, useSetCurrentUser} from 'app/store/auth'
import {AuthService, StorageService} from 'app/services'
import {DASHBOARD_SUMMARY, LOGIN} from 'app/routing/getters'

export function ConsoleSwitcher() {
  const setAuthKey = useSetAuthKey()
  const setCurrentUser = useSetCurrentUser()
  const location = useLocation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    const browserId = params.get('browser_id')
    const redirectTo = params.get('redirect_to') || DASHBOARD_SUMMARY

    if (!token || !browserId) {
      console.error('Missing token or browser ID')
      navigate(LOGIN)
      return
    }

    const initAuth = async () => {
      try {
        StorageService.removeAuthTokens()
        StorageService.removeBrowserId()
        StorageService.setBrowserId(browserId)
        StorageService.setAuthKey(token)
        setAuthKey(token)

        const userData = await AuthService.getUserByToken()
        if (userData) {
          setCurrentUser(userData)
          setIsLoading(false)
          navigate(redirectTo)
        } else {
          throw new Error('No user data received')
        }
      } catch (err) {
        console.error('Error during ZenEx auth:', err)
        StorageService.removeAuthTokens()
        StorageService.removeBrowserId()
        setAuthKey(undefined)
        setCurrentUser(undefined)
        setIsLoading(false)
        navigate(LOGIN)
      }
    }

    initAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  if (!isLoading) return null
  return <Loader containerStyle={{height: '100vh'}} variant={LOADER_VARIANT.ELLIPSES} />
}
