import {Accordion, SVG, SwitchV2, TooltipV2, useAccordionStore} from '@hybr1d-tech/charizard'
import clsx from 'clsx'
import classes from './styles.module.css'
import {ICONS} from 'app/utils/constants/icon'
import {INotificationPreference} from 'types/notifications'
import {useUpdateNotificationPreferences} from 'app/services'

export default function Notification({pref}) {
  const {mutateAsync: updatePreferencesMutate} = useUpdateNotificationPreferences()
  const handleSwitchToggle = async notifPref => {
    await updatePreferencesMutate({id: notifPref.id, is_enabled: !notifPref.is_enabled})
  }

  const activeEventKey = useAccordionStore(state => state.activeEventKey)
  const setActiveEventKey = useAccordionStore(state => state.setActiveEventKey)

  const isCurrentEventKey = activeEventKey.includes(pref.title)

  const setKey = () => {
    const newActiveKeys = isCurrentEventKey
      ? activeEventKey.filter(key => key !== pref.title)
      : [...activeEventKey, pref.title]

    setActiveEventKey(newActiveKeys)
  }
  // console.log(activeEventKey)
  return (
    <Accordion.Item eventKey={pref.title}>
      <Accordion.Header eventKey={pref.title} customClasses="zap-reset-btn">
        <button
          type="button"
          onClick={setKey}
          className={clsx(
            'zap-reset-btn',
            classes.accordionTrigger,
            isCurrentEventKey ? '' : classes.accordionItem,
          )}
        >
          <div className={classes.titleContainer}>
            {isCurrentEventKey ? (
              <SVG path={ICONS.chevronDown} svgClassName={clsx(classes.icon)} />
            ) : (
              <SVG path={ICONS.chevronRight} svgClassName={clsx(classes.icon)} />
            )}
            <p className={classes.title}>{pref.title}</p>
          </div>
          <div className={classes.notificationsCount}>{pref.notificationCount} Notifications</div>
        </button>
      </Accordion.Header>

      <Accordion.Collapse eventKey={pref.title} customClasses={classes.accordionContent}>
        <div className={classes.prefContainer}>
          <div className={classes.prefHeader}>
            <div className={classes.headerText}>NOTIFICATION NAME</div>
            <div className={classes.headerText}>EMAIL</div>
            <div className={classes.headerText}>
              <span>PLATFORM</span>

              <TooltipV2
                trigger={<SVG path={ICONS.infoCircle} height={16} width={16} />}
                content={'These are the notifications you will receive in your browser'}
              />
            </div>
          </div>
          <div className={classes.prefBody}>
            {pref.notifications.map((notification: INotificationPreference) => (
              <div key={notification.name} className={classes.notifRow}>
                <div className={classes.left}>
                  <p className={classes.notificationName}>{notification.name}</p>
                  <p className={classes.notificationDescription}>{notification.description}</p>
                </div>
                <div className={classes.switchContainer}>
                  {notification.email ? (
                    <SwitchV2
                      name={notification.name}
                      checked={notification.email?.is_enabled}
                      onCheckedChange={() => handleSwitchToggle(notification.email)}
                    />
                  ) : (
                    <Restricted notifType="email" />
                  )}
                </div>
                <div className={classes.switchContainer}>
                  {notification.web_notification ? (
                    <SwitchV2
                      name={notification.name}
                      onCheckedChange={() => handleSwitchToggle(notification.web_notification)}
                      checked={notification.web_notification?.is_enabled}
                    />
                  ) : (
                    <Restricted notifType="platform" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Accordion.Collapse>
    </Accordion.Item>
  )
}

function Restricted({notifType = 'email'}: {notifType?: 'email' | 'platform'}) {
  return (
    <TooltipV2
      trigger={<SVG path={ICONS.restricted} svgClassName={classes.restrictedIcon} />}
      content={`No ${notifType} notifications exist for this`}
    />
  )
}
