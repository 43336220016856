import * as React from 'react'
import MasterLayout from 'hybrid-ui/layout/MasterLayout'
import {Route, Routes, Navigate, useLocation, useNavigate} from 'react-router'
import {useModules} from 'app/utils/hooks/use-modules'
import {DASHBOARD_SUMMARY, NOT_FOUND} from './getters'
import {getModuleBasedRouteAccess, getRoleBasedRouteAccess} from './authorized-routes'
import {useModuleControl} from './module-control'
import {SuspensedView, lazyLoad} from './utils'
import {useCurrentUser} from 'app/store/auth'

// Main routers
const DashboardRouter = lazyLoad.component(
  () => import('../modules/dashboard/DashboardRouter'),
  'dashboard',
)

const SettingsRouter = lazyLoad.component(
  () => import('../modules/settings/SettingsRouter'),
  'settings',
)

const TeamRouter = lazyLoad.component(() => import('../modules/team'), 'team')

const WebstoreRouter = lazyLoad.component(
  () => import('../modules/webstore/WebstoreRouter'),
  'webstore',
)

const InventoryRouter = lazyLoad.component(
  () => import('../modules/inventory/InventoryRouter'),
  'inventory',
)

const ReportsRouter = lazyLoad.component(
  () => import('../modules/reports/ReportsRouter'),
  'reports',
)

const TasksRouter = lazyLoad.component(() => import('../modules/tasks'), 'tasks')

const ChecklistRouter = lazyLoad.component(() => import('../modules/checklist'), 'checklists')

const WorkflowsRouter = lazyLoad.component(
  () => import('../modules/workflow/WorkflowRouter'),
  'workflows',
)

const AppStoreRouter = lazyLoad.component(
  () => import('../modules/app-store/AppStoreRouter'),
  'app-store',
)

const ITSupportRouter = lazyLoad.component(
  () => import('../modules/it-support/ITSupportRouter'),
  'it-support',
)

const MDMRouter = lazyLoad.component(() => import('../modules/mdm'), 'mdm')

const DocumentRouter = lazyLoad.component(() => import('../modules/document'), 'document')

const SoftwareRouter = lazyLoad.component(() => import('../modules/software'), 'software')

const AttendanceRouter = lazyLoad.component(
  () => import('../modules/attendance-management/AttendanceRouter'),
  'attendance-management',
)

const LeaveRouter = lazyLoad.component(
  () => import('../modules/leave-management/LeaveRouter'),
  'leave-management',
)

const PerformanceRouter = lazyLoad.component(() => import('../modules/performance'), 'performance')

const ZenAllianceRouter = lazyLoad.component(
  () => import('app/modules/zen-alliance/ZenAllianceRouter'),
  'zen-alliance',
)

export default function PrivateRoutes() {
  const location = useLocation()
  const currentUser = useCurrentUser()
  const navigate = useNavigate()
  const {hasAccess} = useModuleControl(location.pathname)
  const {fetchingModules} = useModules()

  React.useEffect(() => {
    getRoleBasedRouteAccess(currentUser?.role, navigate, location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  React.useEffect(() => {
    if (fetchingModules) return

    getModuleBasedRouteAccess(hasAccess, navigate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingModules, location.pathname])

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to dashboard after success login/registration */}
          <Route path="auth/*" element={<Navigate to={DASHBOARD_SUMMARY} />} />

          <Route
            path="dashboard/*"
            element={
              <SuspensedView>
                <DashboardRouter />
              </SuspensedView>
            }
          />
          <Route
            path="people/team/*"
            element={
              <SuspensedView>
                <TeamRouter />
              </SuspensedView>
            }
          />

          <Route
            path="people/document/*"
            element={
              <SuspensedView>
                <DocumentRouter />
              </SuspensedView>
            }
          />
          <Route
            path="people/leave/*"
            element={
              <SuspensedView>
                <LeaveRouter />
              </SuspensedView>
            }
          />
          <Route
            path="people/attendance/*"
            element={
              <SuspensedView>
                <AttendanceRouter />
              </SuspensedView>
            }
          />

          <Route
            path="devices/inventory/*"
            element={
              <SuspensedView>
                <InventoryRouter />
              </SuspensedView>
            }
          />

          <Route
            path="tools/checklists/*"
            element={
              <SuspensedView>
                <ChecklistRouter />
              </SuspensedView>
            }
          />

          {/* <Route
            path="devices/orders/*"
            element={
              <SuspensedView>
                <OrdersRouter />
              </SuspensedView>
            }
          /> */}

          <Route
            path="zen-it/webstore/*"
            element={
              <SuspensedView>
                <WebstoreRouter />
              </SuspensedView>
            }
          />

          {/* <Route
            path="devices/products/*"

            element={
              <SuspensedView>
                <WebstoreRouter />
              </SuspensedView>
            }
          /> */}

          <Route
            path="tools/tasks/*"
            element={
              <SuspensedView>
                <TasksRouter />
              </SuspensedView>
            }
          />

          <Route
            path="tools/workflows/*"
            element={
              <SuspensedView>
                <WorkflowsRouter />
              </SuspensedView>
            }
          />
          <Route
            path="tools/reports/*"
            element={
              <SuspensedView>
                <ReportsRouter />
              </SuspensedView>
            }
          />
          <Route
            path="software/it-support/*"
            element={
              <SuspensedView>
                <ITSupportRouter />
              </SuspensedView>
            }
          />
          <Route
            path="software/tracking/*"
            element={
              <SuspensedView>
                <SoftwareRouter />
              </SuspensedView>
            }
          />
          <Route
            path="devices/mdm/*"
            element={
              <SuspensedView>
                <MDMRouter />
              </SuspensedView>
            }
          />
          <Route
            path="main-settings/*"
            element={
              <SuspensedView>
                <SettingsRouter />
              </SuspensedView>
            }
          />
          <Route
            path="app-store/*"
            element={
              <SuspensedView>
                <AppStoreRouter />
              </SuspensedView>
            }
          />
          <Route
            path="tools/alliance/*"
            element={
              <SuspensedView>
                <ZenAllianceRouter />
              </SuspensedView>
            }
          />

          <Route
            path="performance/*"
            element={
              <SuspensedView>
                <PerformanceRouter />
              </SuspensedView>
            }
          />

          <Route path="*" element={<Navigate to={NOT_FOUND} />} />
        </Route>
      </Routes>
    </>
  )
}
