export const StorageService = {
  getAuthKey(): string | undefined {
    return _localstorage.getItem('auth_key')
  },
  setAuthKey(authKey: string) {
    return _localstorage.setItem('auth_key', authKey)
  },
  removeAuthTokens() {
    _localstorage.setItem('auth_key', undefined)
  },
  setBrowserId(browserId: string) {
    _localstorage.setItem('browser-id', browserId)
  },
  removeBrowserId() {
    _localstorage.setItem('browser-id', undefined)
  },

  getUserId() {
    return _localstorage.getItem('userId')
  },
  setUserId(value) {
    return _localstorage.setItem('userId', value)
  },
  getWebstoreCountry(id) {
    return _localstorage.getItem(`webstore_country-${id}`)
  },
  setWebstoreCountry(id, country) {
    return _localstorage.setItem(`webstore_country-${id}`, country)
  },
  get(key, fromSessionStorage = false) {
    return _localstorage.getItem(key, fromSessionStorage)
  },
  set(key, value, saveToSessionStorage = false) {
    return _localstorage.setItem(key, value, saveToSessionStorage)
  },
  has(key) {
    return _localstorage.getItem(key) !== undefined
  },
  remove(key) {
    return _localstorage.setItem(key, undefined)
  },
  logout() {
    this.removeAuthTokens()
  },

  getBrowserId(): string | undefined {
    return _localstorage.getItem('browser-id')
  },
}

const _localstorage = {
  getItem(key, fromSessionStorage = false) {
    key = generateKey(key)
    const storage = selectStorage(fromSessionStorage)

    const val = storage.getItem(key)

    if (val === null) return undefined

    try {
      return JSON.parse(val)
    } catch (error) {
      return val // if not, simply return the value.
    }
  },

  setItem(key, value, saveToSessionStorage = false) {
    key = generateKey(key)
    const storage = selectStorage(saveToSessionStorage)

    if (value === undefined || value === null) {
      return storage.removeItem(key)
    }

    if (typeof value === 'object') {
      value = JSON.stringify(value)
    }

    storage.setItem(key, value)
  },
}

function selectStorage(isSessionStore) {
  return !isSessionStore ? localStorage : sessionStorage
}

export function generateKey(key) {
  return '__zenadmin__.' + key
}
