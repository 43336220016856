import * as React from 'react'
import AuthWrapper from '../../shared/components/auth-wrapper'
import classes from './styles.module.css'
import {Button, BUTTON_VARIANT, Loader} from '@hybr1d-tech/charizard'
import {useNavigate, useSearchParams} from 'react-router'
import {handleValidateMagicLink} from '../../utils'
import {useSaveAuth, useSetCurrentUser} from 'app/store/auth'
import {AuthService, StorageService} from 'app/services'
import {LOGIN} from 'app/routing/getters'

export default function MagicLogin() {
  const [isError, setIsError] = React.useState(false)
  const navigate = useNavigate()
  const [URLSearchParams] = useSearchParams()
  const saveAuth = useSaveAuth()
  const setCurrentUser = useSetCurrentUser()

  const didRequest = React.useRef(false)

  const handleLogin = async () => {
    if (didRequest.current) {
      return
    }
    try {
      const refId = URLSearchParams.get('ref_id')
      if (!refId) {
        navigate(LOGIN)
        return
      }
      await handleValidateMagicLink(refId, saveAuth, setCurrentUser)
    } catch (error) {
      setIsError(true)
    }
  }

  React.useEffect(() => {
    handleLogin()
    didRequest.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNavigateToLogin = async () => {
    if (StorageService.has('auth_key')) {
      const user = await AuthService.getUserByToken()
      setCurrentUser(user)
    } else {
      navigate(LOGIN)
    }
  }

  return (
    <AuthWrapper hideLeftContainer>
      {isError ? (
        <>
          <div className={classes.heading}>Error while signing in</div>
          <Button
            variant={BUTTON_VARIANT.PRIMARY}
            onClick={handleNavigateToLogin}
            customStyles={{margin: 'auto'}}
          >
            Back to Login
          </Button>
        </>
      ) : (
        <>
          <div className={classes.heading}>Logging in</div>
          <Loader containerStyle={{height: 'auto'}} />
        </>
      )}
    </AuthWrapper>
  )
}
