import {ModuleID} from './types'

export const INTERNAL_RELEASE_COMPANY_IDS = [
  // ZenAdmin
  'e0a98e70-b2e1-40f3-bea4-b462e5450f3c',
  // ZenAdmin Sales
  '274e1d6f-3ce0-4da1-9769-abd387e29b4a',
  // Hybr1d Production Test
  '99fe5378-d0da-4735-9167-0ba35578e168',
  // staging dev+00990@hybr1d.io
  'f1dae23f-912c-4888-8a58-a9fb138c5fd1',
]

export const INTERNAL_MODULES = [
  ModuleID.AttendanceManagement,

  // Add other internal modules as needed
]

export const isInternalRelease = (companyId?: string) => {
  return companyId && INTERNAL_RELEASE_COMPANY_IDS.includes(companyId)
}
