import clsx from 'clsx'
import classes from './styles.module.css'

// Define the types for the AvatarProps
export interface AvatarProps {
  src?: string
  srcSet?: string
  name: string
  userId: string
  customClass?: string
}

export default function RFQAvatar(props: AvatarProps) {
  // Utility to get initials from the name
  function getInitials(name: string) {
    const words = name.split(/\s+/).filter(w => w.length > 0)
    let firstInitial = ''
    let lastInitial = ''

    const getValidInitial = (word: string) => {
      for (let i = 0; i < word.length; i++) {
        if (/[a-zA-Z]/.test(word[i])) return word[i].toUpperCase()
      }
      return ''
    }
    const meaningfulWords = words.filter(w => !/^\(.*\)$/.test(w))
    const targetWords = meaningfulWords.length > 0 ? meaningfulWords : words
    for (const word of targetWords) {
      firstInitial = getValidInitial(word)
      if (firstInitial) break
    }
    for (let i = targetWords.length - 1; i >= 0; i--) {
      lastInitial = getValidInitial(targetWords[i])
      if (lastInitial) break
    }
    return firstInitial + lastInitial
  }

  return (
    <div>
      {!!props.src ? (
        <img alt={props.name} src={props.src} srcSet={props.srcSet} className={classes.avatarImg} />
      ) : (
        <span className={clsx(classes.initials, 'zap-hero-semibold', props.customClass)}>
          {getInitials(props.name)}
        </span>
      )}
    </div>
  )
}
