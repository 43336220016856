import classes from './styles.module.css'
import {useSidebarMinimized} from 'app/store/layout'
import {motion} from 'motion/react'

export default function CompanyBox({isMobile = false}) {
  const isMini = useSidebarMinimized()
  return (
    <div className={classes.box}>
      {!isMobile ? (
        <>
          <img
            src="/assets/logo-short-white-no-bg.svg"
            alt="ZenAdmin Logo"
            className={classes.logo}
          />
          {!isMini && (
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.35}}>
              <img
                src="/assets/logo-text-white.svg"
                alt="ZenAdmin Logo"
                className={classes.logoFull}
              />
            </motion.div>
          )}
        </>
      ) : (
        <img
          src="/assets/logo-full-white.svg"
          alt="ZenAdmin Logo"
          className={classes.logoMobile}
          style={{padding: '0px 20px'}}
        />
      )}
    </div>
  )
}
